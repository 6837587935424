div[aria-live="polite"] button {
    margin: 0;
}

.css-19kzrtu {
    padding: 15px 15px 0px 15px !important;
}

a._1l0mt {
    width: auto;
}

a._3KoyM {
    height: 49.11px;
}

div._3fM8l {
    height: 49.11px;
}

div._2MOel {
    max-height: 200px;
    overflow-y: auto;
}

body {
    margin: 0px !important;
    overflow-x: hidden;
    height: 100vh;
}

.MuiCardContent-root {
    padding: 0px 16px !important;
    height: 100%;
}

.MuiListItem-root {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

a[disabled] {
    display: none !important;
}
